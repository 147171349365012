import http from '@/libs/http'
import axios from 'axios'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_TECNICA
export default class RecepcionService {
    paginate (params) {
        return http.get(`${baseUrl}/recepcion/paginate`, {
            params,
            headers: {
                loading: false
            }
        })
    }

    find (id, params) {
      return http.get(`${baseUrl}/recepcion/detalle/${id}`, {
        params,
        headers: {
          loading: true
        }
      })
    }

    findOficioByCaja (id) {
      return http.get(`${baseUrl}/recepcion/oficio-caja/${id}`, {
        headers: {
          loading: true
        }
      })
    }

    detalleCaja (id) {
      return http.get(`${baseUrl}/recepcion/detalle-caja/${id}`, {
        headers: {
          loading: true
        }
      })
    }

    verPdf (params) {
      const url = `${baseUrl}/pdf/ver`
      const res = axios.getUri({
        url,
        params
      })
      console.log({ res })
      window.open(res, '_blank')
    }

    put (id, payload) {
      return http.put(`${baseUrl}/recepcion/notificar-recibido/${id}`, payload, {
        headers: {
          loading: true
        }
      })
    }

    registrarActa (id, payload) {
      return http.put(`${baseUrl}/recepcion/registrar-acta/${id}`, payload, {
        headers: {
          loading: true
        }
      })
    }

    post (payload) {
      return http.post(`${baseUrl}/recepcion/generar`, payload, {
        headers: {
          loading: true
        }
      })
    }

    novedadesByOficio (oficio) {
      return http.get(`${baseUrl}/novedades/oficio-novedades/${oficio}`, {
        headers: {
          loading: true
        }
      })
    }

    changeStateNovedades (payload) {
      return http.put(`${baseUrl}/novedades/check-novedades`, payload, {
        headers: {
          loading: true
        }
      })
    }

    notificaciones () {
      return http.get(`${baseUrl}/recepcion/notificaciones`, {
        headers: {
          loading: true
        }
      })
    }

    novedadesByCaja (payload) {
      return http.post(`${baseUrl}/novedades/novedades-caja`, payload, {
        headers: {
          loading: true
        }
      })
    }
}
