import http from '@/libs/http'

export default (params) => {
  const url = `${process.env.VUE_APP_MICROSERVICE_API_RECEPCION_TECNICA}/download/export-excel`
  const res = http.getUri({ url, params }, {
    headers: {
      loading: true
    }
  })
  window.open(res, '_blank')
}
