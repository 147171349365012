<template>
    <section>
      <div class="w-full bg-white py-2">
        <div class="flex w-full p-4 mt-4">
          <img class="mr-4" src="../../../../../../assets/images/hand-money-blue.svg" alt="">
          <p class="text-xl text-blue-800 font-bold">Recepción Técnica</p>
        </div>
        <div class="mt-2 w-full">
            <div class="boxed-tabs lg:hidden lg:nav lg:nav-tabs rounded-md bg-white border-b w-full" role="group">
              <router-link v-for="(item, index) in items"  :key="index"
                      :id="item.id"
                      :to="item.to"
                      :class="item.class"
                      class="p-2 border-0 self-end"
              >
                <div v-if="$can(item.permission)">
                  {{ item.label }}
                </div>
              </router-link>
              <div v-if="items[0].class === 'on' && $can('pharmasan.logistica.recepcion-tecnica.access')" class="flex items-center justify-end mt-4 gap-4 lg:w-full my-1">
                <Dropdown class="mb-2" @change="exportarExcel" v-model="exportarType" :options="tiposExport" placeholder="Exportar">
                    <template #value="slotProps">
                        <div class="flex gap-2">
                          <i class="pi pi-file-excel mr-2"></i>
                          <p>
                            {{slotProps.placeholder}}
                          </p>
                        </div>
                    </template>
                </Dropdown>
                <el-popover
                  placement="bottom-start"
                  :width="200"
                  trigger="hover"
                >
                  <div class="bg-white absolute z-10 w-40 max-h-60 overflow-y-auto border rounded-md">
                    <div v-if="notificaciones.length">
                      <div class="border-b" v-for="(notificacion, i) in (notificaciones.length > 10 ? notificaciones.splice(9) : notificaciones)" :key="i">
                        <button @click="filtrarNotificacion(1, notificacion)" class="bg-white hover:bg-blue-100 my-1 w-full p-4 text-left text-xs">
                          {{notificacion.observacion}}
                        </button>
                      </div>
                      <div class="flex justify-end w-full">
                        <button @click="filtrarNotificacion(2, { todas: true })" class="text-blue-600 text-xs p-1">Ver todas</button>
                      </div>
                    </div>
                    <div v-else>
                      No tienes notificaciones pendientes
                    </div>
                  </div>
                  <template #reference>
                    <div class="relative">
                      <Button icon="pi pi-bell" style="z-index: 1;" class="p-button-rounded p-button-warning" />
                      <div v-if="notificaciones.length" style="top: 0; right: 0; transform: translate(40%,-40%); transform-origin: 100% 0; margin: 0; z-index: 99;" class="rounded-full bg-red-600 absolute w-4 h-4 flex items-center justify-center">
                        <p v-if="notificaciones.length < 10" class="text-white text-xs">
                          {{notificaciones.length}}
                        </p>
                        <p v-else class="text-white text-xs">
                          9+
                        </p>
                      </div>
                    </div>
                  </template>
                </el-popover>
                <div class="flex items-center">
                  <label for="barCodeBox">
                    <svg style="width:24px;height:24px; cursor: pointer" viewBox="0 0 24 24">
                        <path fill="black" d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z" />
                    </svg>
                  </label>
                  <input id="barCodeBox" @keyup.enter="sendBarCodeBox" v-model="barCodeBox" :class="barCodeBox ? 'bg-green-600 border-green-600 text-green-600': ''" class="mx-2 bg:white border-0 w-2 h-3 rounded-full text-green-600 focus:border-green-600 focus:bg-green-600 focus:shadow-md " style="width: 10px;" type="text">
                  <p class="ml-2">
                    {{barCodeBox}}
                  </p>
                  <button v-if="barCodeBox" @click="barCodeBox = ''" class="rounded-full mx-2 w-4 flex items-center justify-center h-4 bg-red-600">
                    <i class="pi pi-times text-white" style="font-size: 0.5rem;"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="boxed-tabs hidden lg:flex nav nav-tabs rounded-md bg-white border-b w-full" role="group">
              <router-link v-for="(item, index) in items"  :key="index"
                      :id="item.id"
                      :to="item.to"
                      :class="item.class"
                      class="p-2 border-0 self-end"
              >
                <div v-if="$can(item.permission)">
                  {{ item.label }}
                </div>
              </router-link>
              <div v-if="items[0].class === 'on' && $can('pharmasan.logistica.recepcion-tecnica.access')" class="flex items-center lg:justify-end gap-4 w-full my-1">
                <Dropdown class="mb-2" @change="exportarExcel" v-model="exportarType" :options="tiposExport" placeholder="Exportar">
                    <template #value="slotProps">
                        <div class="flex gap-2">
                          <i class="pi pi-file-excel mr-2"></i>
                          <p>
                            {{slotProps.placeholder}}
                          </p>
                        </div>
                    </template>
                </Dropdown>
                <el-popover
                  placement="bottom-start"
                  :width="200"
                  trigger="hover"
                >
                  <div class="bg-white absolute z-10 w-40 max-h-60 overflow-y-auto border rounded-md p-2">
                    <div v-if="notificaciones.length">
                      <div class="border-b" v-for="(notificacion, i) in (notificaciones.length > 10 ? notificaciones.splice(9) : notificaciones)" :key="i">
                        <button @click="filtrarNotificacion(1, notificacion)" class="bg-white hover:bg-blue-100 my-1 w-full p-4 text-left text-xs">
                          {{notificacion.observacion}}
                        </button>
                      </div>
                      <div class="flex justify-end w-full">
                        <button @click="filtrarNotificacion(2, { todas: true })" class="text-blue-600 text-xs p-1">Ver todas</button>
                      </div>
                    </div>
                    <div v-else>
                      No tienes notificaciones pendientes
                    </div>
                  </div>
                  <template #reference>
                    <div class="relative">
                      <Button icon="pi pi-bell" style="z-index: 1;" class="p-button-rounded p-button-warning" />
                      <div v-if="notificaciones.length" style="top: 0; right: 0; transform: translate(40%,-40%); transform-origin: 100% 0; margin: 0; z-index: 99;" class="rounded-full bg-red-600 absolute w-4 h-4 flex items-center justify-center">
                        <p v-if="notificaciones.length < 10" class="text-white text-xs">
                          {{notificaciones.length}}
                        </p>
                        <p v-else class="text-white text-xs">
                          9+
                        </p>
                      </div>
                    </div>
                  </template>
                </el-popover>
                <div class="flex items-center">
                  <label for="barCodeBox">
                    <svg style="width:24px;height:24px; cursor: pointer" viewBox="0 0 24 24">
                        <path fill="black" d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z" />
                    </svg>
                  </label>
                  <input id="barCodeBox" @keyup.enter="sendBarCodeBox" v-model="barCodeBox" :class="barCodeBox ? 'bg-green-600 border-green-600 text-green-600': ''" class="mx-2 bg:white border-0 w-2 h-3 rounded-full text-green-600 focus:border-green-600 focus:bg-green-600 focus:shadow-md " style="width: 10px;" type="text">
                  <p class="ml-2">
                    {{barCodeBox}}
                  </p>
                  <button v-if="barCodeBox" @click="barCodeBox = ''" class="rounded-full mx-2 w-4 flex items-center justify-center h-4 bg-red-600">
                    <i class="pi pi-times text-white" style="font-size: 0.5rem;"></i>
                  </button>
                </div>
              </div>
            </div>
            <router-view />
        </div>
      </div>
    </section>
  </template>
  <script>
    import { useRoute } from 'vue-router'
    import { computed, onMounted, ref } from 'vue'
    import RecepcionStore from '../../store/recepcion.store'
    import RecepcionService from '../../services/recepcion.service'
    import exportService from '../../services/export.service'
    import exportTrazabilidadService from '../../services/exportTrazabilidad.service'
    import {
      ElPopover
    } from 'element-plus/dist/index.full.js'
    import dayjs from 'dayjs'
    export default {
    name: 'RecepcionTecnicaLogistica',
    components: {
      ElPopover
    },
    setup () {
      // Services
      const route = useRoute()
      const _RecepcionService = ref(new RecepcionService())
      // References
      const barCodeBox = ref('')
      const notificaciones = ref([])
      const exportarType = ref('')
      const displayNotificaciones = ref(false)
      const tiposExport = ref(['Exportar oficios filtrados', 'Exportar trazabilidad medicamento'])
      // Computed
      const params = computed(() => RecepcionStore.getters._filtros)
      // Methods
      const items = computed(() => {
        return [
            {
              id: 'estado-recepcion',
              to: { name: 'pharmasan.compras.logistica.recepcion-tecnica.estado-recepcion', params: { type: 'estado' } },
              class: getActive([
                  'pharmasan.compras.logistica.recepcion-tecnica.estado-recepcion'
              ]),
              permission: 'pharmasan.compras.logistica.recepcion-tecnica',
              label: 'Estado de Recepción'
            },
            {
              id: 'recepcion',
              to: { name: 'pharmasan.compras.logistica.recepcion-tecnica.recepcion', params: { type: 'recepcion' } },
              class: getActive([
                  'pharmasan.compras.logistica.recepcion-tecnica.recepcion'
              ]),
              permission: 'pharmasan.compras.logistica.recepcion-tecnica.access',
              label: 'Recepcion'
            }
        ]
      })
      const getActive = (routeNames = []) => {
          if (routeNames.includes(route.name)) {
          return 'on'
          }
          return 'off'
      }
      const exportarExcel = () => {
        if (exportarType.value === 'Exportar oficios filtrados') {
          console.log('this is params--->', params.value)
          return exportService(params.value)
        } else {
          const object = {
            itemCode: '',
            lote: ''
          }
          return exportTrazabilidadService(object)
        }
      }
      const getNotificaciones = () => {
        _RecepcionService.value.notificaciones().then(({ data }) => {
          data.estadosMayores.map(a => {
            notificaciones.value.push({
              observacion: `El oficio ${a.doc_num} se encuentra retrasado`,
              oficio: a.doc_num,
              id: a.id,
              fecha: a.created_at
            })
          })
          data.cajasSinRegistro.map(a => {
            notificaciones.value.push({
              observacion: `El oficio ${a.doc_num} tiene cajas sin registrar`,
              oficio: a.doc_num,
              id: a.id,
              fecha: a.created_at
            })
          })
        })
      }
      const filtrarNotificacion = (type, data) => {
        if (type === 1) {
          RecepcionStore.commit('setfiltroNotificacionOficio', { oficio: data.oficio, fecha: data.fecha, fechaFin: data.fechaFin, todas: data.todas })
        } else {
          const object = []
          for (const i of notificaciones.value) {
            object.push(i.oficio)
          }
          RecepcionStore.commit('setfiltroNotificacionOficio', { oficio: object, fecha: '', fechaFin: dayjs(), todas: data.todas })
        }
        displayNotificaciones.value = false
      }
      const sendBarCodeBox = () => {
        RecepcionStore.commit('setBarCodeOficio', barCodeBox.value)
        barCodeBox.value = ''
      }
      onMounted(() => {
        getActive(['pharmasan.compras.logistica.recepcion-tecnica.estado-recepcion'])
        getNotificaciones()
      })
      return {
        items,
        exportarExcel,
        params,
        barCodeBox,
        notificaciones,
        displayNotificaciones,
        filtrarNotificacion,
        dayjs,
        sendBarCodeBox,
        tiposExport,
        exportarType
      }
    }
  }
  </script>

  <style scoped>
    /* ::v-deep(.p-tieredmenu .p-menuitem) {
      background-color: red;
    } */
    ::v-deep(.p-tieredmenu .p-menuitem a) {
      background-color: green !important;
    }
    .on {
      border-bottom: 3px solid #1C3FAA;
      width: 12%;
      text-align: center;
    }
    .off {
      width: 12%;
      text-align: center;

    }
    ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::v-deep(.p-dropdown-trigger) {
    display: none;
  }
  </style>
