import { createStore } from 'vuex'
// import RecepcionService from '../services/recepcion.service'
// const _RecepcionService = new RecepcionService()

const RecepcionStore = {
  state: {
    recepcion: {},
    novedades: [],
    filtros: {},
    recepcionDetails: {},
    barCodeOficio: 0,
    filtroNotificacionOficio: {}
  },
  getters: {
    _recepcion: (state) => state.recepcion,
    _novedades: (state) => state.novedades,
    _filtros: (state) => state.filtros,
    _recepcionDetails: (state) => state.recepcionDetails,
    _barCodeOficio: (state) => state.barCodeOficio,
    _filtroNotificacionOficio: (state) => state.filtroNotificacionOficio
  },
  mutations: {
    setRecepcion (state, payload) {
      state.recepcion = payload
    },
    setNovedades (state, payload) {
      state.novedades = payload
    },
    setFiltros (state, payload) {
      state.filtros = payload
    },
    setRecepcionDetails (state, payload) {
      state.recepcionDetails = payload
    },
    setBarCodeOficio (state, payload) {
      state.barCodeOficio = payload
    },
    setfiltroNotificacionOficio (state, payload) {
      state.filtroNotificacionOficio = payload
    }
  },
  actions: {
  }
}
const store = createStore(RecepcionStore)
export default {
  namespaced: true,
  ...store
}
